// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA1RYxTzQug9tmAC1XUtJnKjlL6Fwe-eo0",
  authDomain: "ichthusweb-ef3cd.firebaseapp.com",
  projectId: "ichthusweb-ef3cd",
  storageBucket: "ichthusweb-ef3cd.appspot.com",
  messagingSenderId: "967594303895",
  appId: "1:967594303895:web:645fea9955aaa98baa24d2",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

export default app;
