import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  isLoggedIn: false,
  email: null,
  userName: null,
  userID: null,
  products: [],
  checkedBrands: [],
  checkedCategories: [],
};

export const IchthusSlice = createSlice({
  name: "Ichthus",
  initialState,
  reducers: {
    // Auth reducers
    SET_ACTIVE_USER: (state, action) => {
      const { email, userName, userID } = action.payload;
      state.isLoggedIn = true;
      state.email = email;
      state.userName = userName;
      state.userID = userID;
    },
    REMOVE_ACTIVE_USER: (state) => {
      state.isLoggedIn = false;
      state.email = null;
      state.userName = null;
      state.userID = null;
      state.products = [];
      state.checkedBrands = [];
      state.checkedCategories = [];
      localStorage.removeItem("cartItems");
    },

    // Cart reducers
    addToCart: (state, action) => {
      // Find if the item with the same id and color already exists in the cart
      const item = state.products.find(
        (item) =>
          item.id === action.payload.id && item.colors === action.payload.colors
      );

      if (item) {
        item.quantity += action.payload.quantity; // If it exists, increase the quantity
      } else {
        state.products.push({ ...action.payload }); // If it doesn't exist, add it to the cart
      }

      toast.success("Product added to cart");
    },

    deleteItem: (state, action) => {
      const { id, color } = action.payload;
      state.products = state.products.filter(
        (item) => !(item.id === id && item.colors === color)
      );
      toast.error("Product removed from cart");
    },
    increaseQuantity: (state, action) => {
      const { id, color } = action.payload;
      const item = state.products.find(
        (item) => item.id === id && item.colors === color
      );
      if (item) {
        item.quantity++;
      }
    },
    decreaseQuantity: (state, action) => {
      const { id, color } = action.payload;
      const item = state.products.find(
        (item) => item.id === id && item.colors === color
      );
      if (item && item.quantity > 1) {
        item.quantity--;
      }
    },
    resetCart: (state) => {
      state.products = [];
    },

    // Brand and Category reducers
    toggleBrand: (state, action) => {
      const brand = action.payload;
      const isBrandChecked = state.checkedBrands.some((b) => b.id === brand.id);
      if (isBrandChecked) {
        state.checkedBrands = state.checkedBrands.filter(
          (b) => b.id !== brand.id
        );
      } else {
        state.checkedBrands.push(brand);
      }
    },
    toggleCategory: (state, action) => {
      const category = action.payload;
      const isCategoryChecked = state.checkedCategories.some(
        (c) => c.id === category.id
      );
      if (isCategoryChecked) {
        state.checkedCategories = state.checkedCategories.filter(
          (c) => c.id !== category.id
        );
      } else {
        state.checkedCategories.push(category);
      }
    },
  },
});

// Export actions
export const {
  SET_ACTIVE_USER,
  REMOVE_ACTIVE_USER,
  addToCart,
  increaseQuantity,
  decreaseQuantity,
  deleteItem,
  resetCart,
  toggleBrand,
  toggleCategory,
} = IchthusSlice.actions;

// Export selectors
export const selectIsLoggedIn = (state) => state.orebiReducer.isLoggedIn;
export const selectEmail = (state) => state.orebiReducer.email;
export const selectUserName = (state) => state.orebiReducer.userName;
export const selectUserID = (state) => state.orebiReducer.userID;
export const selectCartProducts = (state) => state.orebiReducer.products;

// Export the reducer
export default IchthusSlice.reducer;
