import React, { useContext, lazy, Suspense, startTransition } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  createRoutesFromElements,
  Route,
  ScrollRestoration,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/redux/store";
import { AuthContext } from "./redux/AuthContext";
import Loader from "./components/loader/Loader";
import Admin from "./pages/Admin/Admin";
const Footer = lazy(() => import("./components/home/Footer/Footer"));
const Delivery = lazy(() =>
  import("./components/home/Footer/FooterInfo/Delivery")
);
const PaymentFAQ = lazy(() =>
  import("./components/home/Footer/FooterInfo/PaymentFAQ")
);
const WarrantyInformation = lazy(() =>
  import("./components/home/Footer/FooterInfo/WarrantyInformation")
);
const PrivacyPolicy = lazy(() =>
  import("./components/home/Footer/FooterInfo/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("./components/home/Footer/FooterInfo/TermsAndConditions")
);
const PurchasePolicies = React.lazy(() =>
  import("./components/home/Footer/FooterInfo/PurchasePolicies")
);
const FooterBottom = lazy(() =>
  import("./components/home/Footer/FooterBottom")
);
const Header = lazy(() => import("./components/home/Header/Header"));
const SpecialCase = lazy(() => import("./components/SpecialCase/SpecialCase"));
const About = lazy(() => import("./pages/About/About"));
const SignIn = lazy(() => import("./pages/Account/SignIn"));
const SignUp = lazy(() => import("./pages/Account/SignUp"));
const Cart = lazy(() => import("./pages/Cart/Cart"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const Home = lazy(() => import("./pages/Home/Home"));
const Services = lazy(() => import("./pages/Services/Services"));
const Offer = lazy(() => import("./pages/Offer/Offer"));
const Payment = lazy(() => import("./pages/payment/Payment"));
const ProductDetails = lazy(() =>
  import("./pages/ProductDetails/ProductDetails")
);
const OrderHistory = lazy(() => import("./pages/orderHistory/OrderHistory"));
const Shop = lazy(() => import("./pages/Shop/Shop"));
const Reset = lazy(() => import("./pages/Account/Reset"));

const Layout = () => {
  return (
    <Suspense fallback={<Loader />}>
      <div>
        <ToastContainer
          position="top-right"
          autoClose={1000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <Header />
        <SpecialCase />
        <ScrollRestoration />
        <Outlet />
        <Footer />
        <FooterBottom />
      </div>
    </Suspense>
  );
};

function App() {
  const RequireAuth = ({ children }) => {
    const { isLoggedIn } = useContext(AuthContext);

    if (!isLoggedIn) {
      // Wrap the navigation in startTransition
      startTransition(() => {
        return <Navigate to="/signin" />;
      });
      return null; // Ensure nothing is rendered if the user is not logged in
    }

    return children;
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/shop" element={<Shop />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/category/:category" element={<Offer />} />
          <Route path="/product/:_id" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/paymentFAQ" element={<PaymentFAQ />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/terms" element={<TermsAndConditions />} />
          <Route path="/purchasePolicies" element={<PurchasePolicies />} />
          <Route
            path="/warrantyInformation"
            element={<WarrantyInformation />}
          />
          <Route
            path="/paymentgateway"
            element={
              <RequireAuth>
                <Payment />
              </RequireAuth>
            }
          />
        </Route>
        <Route
          path="/signup"
          element={
            <Suspense fallback={<Loader />}>
              <SignUp />
            </Suspense>
          }
        />
        <Route
          path="/signin"
          element={
            <Suspense fallback={<Loader />}>
              <SignIn />
            </Suspense>
          }
        />
        <Route
          path="/reset"
          element={
            <Suspense fallback={<Loader />}>
              <Reset />
            </Suspense>
          }
        />
        <Route
          path="/admin"
          element={
            <Suspense fallback={<Loader />}>
              <RequireAuth>
                <Admin />
              </RequireAuth>
            </Suspense>
          }
        />
      </Route>
    )
  );

  return (
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <div className="font-bodyFont">
          <RouterProvider router={router} />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
